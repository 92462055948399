<template>
  <div id="page-user-list">
      <vs-button color="success" type="filled" to="/seo-pages/create" icon="add" class="mb-5">Create</vs-button>
      <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
      <!-- AgGrid Table -->
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="seo_pages.data"
          rowSelection="multiple"
          colResizeDefault="shift"
          :animateRows="true"
          :floatingFilter="true"
          :pagination="true"
          :paginationPageSize="seo_pages.pagination.per_page"        
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
          @filter-changed="onFilterChanged"
          @filter-modified="onFilterModified">
        </ag-grid-vue> <!-- End AgGrid Table -->
        <vs-pagination :total="seo_pages.pagination.last_page" :value="seo_pages.pagination.current_page" @input="paginate({page: $event})" />
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'


// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererStatus from "./cell-renderer/CellRendererStatus.vue"
import loaderDivMixin from '../../../mixins/loaderDivMixin';

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    AgGridVue,
    vSelect,
    CellRendererActions,
    CellRendererStatus
  },
  mixins: [loaderDivMixin],
   computed: {
    ...mapGetters({
      seo_pages: 'seo_page/getSeoPages',
      search: 'amenity/getSearchObj',
    }),
    searchParams(){
        let params = new URLSearchParams();
        params.append("page", this.page? this.page: 1);
        (this.search.query.id != '') ? params.append("query[id]", this.search.query.id) : '';
        (this.search.query.name != '') ? params.append("query[name]", this.search.query.name) : '';
        (this.search.query.display_name != '') ? params.append("query[display_name]", this.search.query.display_name) : '';
        (this.search.query.type != '') ? params.append("query[type]", this.search.query.type) : '';

        return params;
    }
  },
   methods: {
    ...mapActions('seo_page', [
      'fetchAndSetSeoPages',
      'setSearchParameters',
      'clearSearchParameters',
    ]),
    paginate(page){
      this.page=page.page;
      this.fetchSeoPages();
    },
    onFilterChanged($event){
      this.setParams($event);
      this.fetchSeoPages();
    },
    onFilterModified($event){
      this.setParams($event);
      this.fetchSeoPages();
    },
    setParams($event){
      let $this = this;
      const searchKeys = Object.keys($event.api.getFilterModel());
      if(searchKeys.length == 0) this.clearSearchParameters();
      searchKeys.forEach(function(key, val){
        $this.setSearchParameters({key: key, value: $event.api.getFilterModel()[key].filter});
      });
    },
    fetchSeoPages(){
      this.openLoading();
      this.fetchAndSetSeoPages({query: this.searchParams})
          .then((res) => this.closeLoading())
          .catch((error) => this.closeLoading());
    }
  },
  data() {
    return {

      // AgGrid
      page: 1,
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          filter: 'agNumberColumnFilter',
          width: 150,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Seopageable Type',
          field: 'seopageable_type',
          filter: 'agTextColumnFilter',
          width: 250,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Region',
          field: 'region.name',
          filter: true,
          width: 300,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Title',
          field: "title",
          filter: true,
          filterParams: {
            clearButton: true,
            debounceMs: 200,
            newRowsAction: 'keep'
          }
        },
        {
          headerName: 'Actions',
          field: 'transactions',
          // width: 150,
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererActions,
        CellRendererStatus
      }
    }
  },
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
